// mobileChess.js

// Function to detect a mobile device
function isMobileDevice() {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    console.log('isMobileDevice:', isMobile); // Log to confirm detection
    return isMobile;
}

// Module-scoped variable for selected square
let selectedSquare = null;

// Function to setup select and place board for mobile
function setupSelectAndPlaceBoard(gameConfig, game, updateStatusCallback, makeMoveFunction) {
    console.log('Setting up board for mobile'); // This will confirm entry into this function
    if (!isMobileDevice()) {
        console.log('Not using mobileConfig for board setup');
        return Chessboard('myBoard', gameConfig);
    }

    console.log('Using mobileConfig for board setup');
    let mobileConfig = {
        ...gameConfig,
        draggable: false, // Confirm dragging is disabled
    };

    return Chessboard('myBoard', mobileConfig);
}

function attachSquareClickListeners(board, makeMoveFunction, updateStatusCallback) {
    console.log("in attachSquareClickListeners")
    console.log("selected square", selectedSquare)
    const squares = document.querySelectorAll('.square-55d63'); // Adjust the selector based on your chessboard's square class
    squares.forEach(square => {
        square.addEventListener('click', (event) => {
            const squareId = square.dataset.square; // Assuming each square has a data attribute like `data-square="e4"`
            onSquareClick(squareId, board, makeMoveFunction, updateStatusCallback);
        });
    });
}

function onSquareClick(square, board, makeMoveFunction, updateStatusCallback) {
    console.log('onSquareClick Square clicked:', square); // Debugging
    console.log("onSquareClick selected square", selectedSquare);

    // Deselection logic
    if (selectedSquare === square) {
        console.log("Deselecting square");
        selectedSquare = null;
        removeSquareHighlights(board);
    } else if (selectedSquare) {
        console.log(`Attempting move from ${selectedSquare} to ${square}`);
        try {
            // Attempt to make the move
            const move = makeMoveFunction(selectedSquare, square);
            // If the move is successful, `chess.js` would not throw, and you can proceed.
            console.log("Move successful", move);
            selectedSquare = null;
            // Optionally call updateStatusCallback here if the move was successful
            updateStatusCallback();
        } catch (error) {
            // Handle the error if the move was invalid
            console.error("Move invalid", error);
            selectedSquare = null;
            removeSquareHighlights(board);
            // Here, the 'snapback' effect needs to be managed manually since you're catching an error
            // You might need to update the board's position or trigger a visual feedback for the invalid move
        }
    } else {
        // First click, select the square
        console.log("Selecting square:", square);
        selectedSquare = square;
        highlightSquare(square, board); // Ensure this is implemented
    }
}


function highlightSquare(square, board) {
    const $square = $(`#${square}`);
    const background = $square.css('background');
    $square.css('background', 'red');
}

function removeSquareHighlights() {
    $('#myBoard .square-55d63').css('background', '')
}
// Add implementations for makeMove, highlightSquare, removeSquareHighlights as needed

export { isMobileDevice, setupSelectAndPlaceBoard, removeSquareHighlights, attachSquareClickListeners };
