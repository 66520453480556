// TODO:
//  Change board size to be dynamic
// spinner while waiting for explain move and suggest move
// Format text from explain move
// Mobile controls
// populate move1 with suggest move
// once a piece is played in Game mode then fold up settings


// import * as Chess from 'chess.js'
import { Chess } from 'chess.js'
import { validateFen } from 'chess.js'
;


// import  validateFen as validateFen from 'chess.js'
import config from './config.js';
import {isMobileDevice, setupSelectAndPlaceBoard, onSquareClick, attachSquareClickListeners, highlightSquare, removeSquareHighlights } from './mobile.js';
// import {isMobileDevice, setupSelectAndPlaceBoard, makeMove, highlightSquare, removeSquareHighlights } from './mobile.js';


document.addEventListener('DOMContentLoaded', () => {
    const practiceConfig = {
        draggable: true,
        position: 'start',
        orientation: 'white',
        showNotation: true,
        sparePieces: true,
        dropOffBoard: 'trash',
        pieceTheme: 'img/chesspieces/wikipedia/{piece}.png'
    };

    const gameConfig = {
        draggable: true,
        position: 'start',
        showNotation: true,
        orientation: 'white',
        sparePieces: false, // No spare pieces in game mode
        onDragStart: onDragStart,
        onDrop: onDrop,
        onSnapEnd: onSnapEnd,
        onMouseoutSquare: onMouseoutSquare,
        onMouseoverSquare: onMouseoverSquare,
        pieceTheme: 'img/chesspieces/wikipedia/{piece}.png'
    };

    const whiteSquareGrey = '#a9a9a9'
    const blackSquareGrey = '#696969'
    let onePlayerRadio = document.getElementById('onePlayer');
    let twoPlayersRadio = document.getElementById('twoPlayers');
    let eloInputDiv = document.getElementById('eloInputDiv');
    var game = new Chess();
    var $status = $('#status');
    var $fen = $('#fen');
    var $pgn = $('#pgn');
    var board; // Declare the board variable without initializing it here

    if (isMobileDevice()) {
        // Initialize the board for mobile
        board = setupSelectAndPlaceBoard(gameConfig, game, updateStatus, makeMove);
        attachSquareClickListeners(board, makeMove, updateStatus);
    } else {
        // Initialize the board for desktop
        board = Chessboard('myBoard', practiceConfig);
    }

    function updateBoardOrientation() {
        const newOrientation = document.getElementById('flipOrientationCheckbox').checked ? 'black' : 'white';
        board.orientation(newOrientation);
    }

    function onDragStart(source, piece, position, orientation) {
        // do not pick up pieces if the game is over
        if (game.isGameOver()) return false

        // only pick up pieces for the side to move
        if ((game.turn() === 'w' && piece.search(/^b/) !== -1) ||
            (game.turn() === 'b' && piece.search(/^w/) !== -1)) {
            return false
        }
    }

    function isPawnPromotion(source, target) {
        // Get the piece at the source location
        let piece = game.get(source);
        // Check if the piece is a pawn and the target is on the last rank
        return piece && piece.type === 'p' && (target[1] === '8' || target[1] === '1');
    }

    function showPromotionPopup(source, target) {
        // Display the popup for promotion choice
        // This is a simple example. You might need to create a proper popup in your HTML
        let promotionPiece = prompt("Promote to (q, r, b, n):", "q");
        makeMove(source, target, promotionPiece);
    }

    function makeMove(source, target, promotion = 'q') {
        // console.log("makeMove called", { from: source, to: target, promotion: promotion });
        let move = game.move({
            from: source,
            to: target,
            promotion: promotion
        });

        if (move === null) return 'snapback'; // Invalid move

        board.position(game.fen()); // Update the board with the new position
        updateStatus();

        return move;
    }

    let pendingPromotion = null;

    function showPromotionPopup(source, target) {
        pendingPromotion = { source, target };
        document.getElementById('promotionPopup').style.display = 'block';
    }

    function selectPromotion(piece) {
        document.getElementById('promotionPopup').style.display = 'none';
        if (pendingPromotion) {
            makeMove(pendingPromotion.source, pendingPromotion.target, piece);
        }
        pendingPromotion = null; // Reset the pending promotion
    }


    function onDrop(source, target) {
        try {
            // console.log("onDrop called", { source: source, target: target });
            // Check if the move is a pawn promotion
            if (isPawnPromotion(source, target)) {
                // Temporarily prevent the move and ask for the promotion choice
                showPromotionPopup(source, target);
            } else {
                // If not a pawn promotion, just make the move
                let move = makeMove(source, target);
                if (move === null) return 'snapback'; // If move is not valid, snapback will be triggered

                // Disable ELO input after the first move
                if (game.history().length === 1) {
                    document.getElementById('eloInput').disabled = true;
                }

                removeGreySquares();
                updateStatus();

                // Check if it's game mode and one player, then get the suggested move
                if (document.getElementById('gameModeRadio').checked &&
                    document.getElementById('onePlayer').checked) {
                    getSuggestedMove();
                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
            return 'snapback'; // Return snapback on error
        }
    }


    function onSnapEnd() {
        board.position(game.fen());
    }

    function onMouseoverSquare(square, piece) {
        // get list of possible moves for this square
        var moves = game.moves({
            square: square,
            verbose: true
        })

        // exit if there are no moves available for this square
        if (moves.length === 0) return

        // highlight the square they moused over
        greySquare(square)

        // highlight the possible squares for this piece
        for (var i = 0; i < moves.length; i++) {
            greySquare(moves[i].to)
        }
    }

    function onMouseoutSquare(square, piece) {
        removeGreySquares()
    }

    function removeGreySquares() {
        $('#myBoard .square-55d63').css('background', '')
    }

    function greySquare(square) {
        var $square = $('#myBoard .square-' + square)

        var background = whiteSquareGrey
        if ($square.hasClass('black-3c85d')) {
            background = blackSquareGrey
        }

        $square.css('background', background)
    }

    function getSuggestedMove() {
        const currentFEN = game.fen();
        const url = new URL(`${config.API_URL}/get_move`);
        url.searchParams.append('fen', currentFEN);

        // Check if in game mode and one player is selected
        if (document.getElementById('gameModeRadio').checked &&
            document.getElementById('onePlayer').checked) {
            const elo = document.getElementById('eloInput').value || '1000';
            url.searchParams.append('elo', elo);
        }

        // Determine the player's turn based on the whiteTurn radio button
        let playerTurn = document.getElementById('whiteTurn').checked ? 'w' : 'b';

        fetch(url) // Default method for fetch is 'GET'
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                if (data && data.move.length > 0) {
                    const source = data.move.substring(0, 2);
                    const target = data.move.substring(2, 4);

                    if (game.turn() === playerTurn) {
                        // It's the player's turn, just highlight the move
                        highlightMove(data.move);
                    } else if (data.one_player) {
                        // It's the AI's turn, make the move for one player mode
                        game.move({ from: source, to: target, promotion: 'q' }); // assuming promotion to queen for simplicity
                        board.position(game.fen()); // Update the board position
                        updateStatus(); // Update the game status
                    }

                    console.log(data.stockfish); // Log additional information if needed
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function getGameScore() {
        const currentFEN = game.fen();
        const url = new URL(`${config.API_URL}/get_score`);
        url.searchParams.append('fen', currentFEN);

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok: ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                updateScoreDisplay(data);
                // if (data && data.score) {
                // }
            })
            .catch((error) => {
                console.error('Error fetching game score:', error);
                console.log(error.response);
                updateScoreDisplay("N/A");
            });
    }

    function updateScoreDisplay(score) {
        var $scoreDisplay = $('#scoreDisplay'); // Update this with your actual score display element's ID
        $scoreDisplay.text(`Current Score: ${score}`);
    }

    function highlightMove(move) {
        const fromSquare = move.substring(0, 2);
        const toSquare = move.substring(2, 4);

        // Clear previous highlights
        removeGreySquares();
        removeSuggestedMoveHighlights();

        // Add new highlights
        $('#myBoard .square-' + fromSquare).addClass('square-suggested-move');
        $('#myBoard .square-' + toSquare).addClass('square-suggested-move');
        // // Highlight the 'from' and 'to' squares
        // greySquare(fromSquare);
        // greySquare(toSquare);

        // Optionally, remove the highlights after some time
        setTimeout(() => {
            removeSuggestedMoveHighlights();
        }, 5000); // Remove highlights after 5 seconds



    }

    function removeSuggestedMoveHighlights() {
        $('#myBoard .square-55d63').removeClass('square-suggested-move');
    }

    function updateStatus() {
        var status = ''

        var moveColor = 'White'
        if (game.turn() === 'b') {
            moveColor = 'Black'
        }
        // checkmate?
        if (game.isCheckmate()) {
            status = 'Game over, ' + moveColor + ' is in checkmate.'
        }
        // draw?
        else if (game.isDraw()) {
            status = 'Game over, drawn position'
        }
        // game still on
        else {
            status = moveColor + ' to move'
            // check?
            if (game.isCheckmate()) {
                status += ', ' + moveColor + ' is in check'
            }
        }

        $status.html(status)
        $fen.html(game.fen())
        $pgn.html(game.pgn())
        getGameScore()
    }

    function undoMove() {
        game.undo(); // Undo the last move in the chess game logic
        board.position(game.fen()); // Update the board position
        updateStatus(); // Update status to reflect the change
    }

    function determineCastlingAvailability(fen) {
        const board = fen.split(' ')[0];
        let castlingAvailability = '';

        // Kings' and Rooks' original positions
        const whiteKingInitialPos = 'e1';
        const blackKingInitialPos = 'e8';
        const whiteKingsideRookInitialPos = 'h1';
        const whiteQueensideRookInitialPos = 'a1';
        const blackKingsideRookInitialPos = 'h8';
        const blackQueensideRookInitialPos = 'a8';

        // Mapping board positions to FEN rows
        const positionMap = {
            'a1': board[56], 'b1': board[57], 'c1': board[58], 'd1': board[59], 'e1': board[60], 'f1': board[61], 'g1': board[62], 'h1': board[63],
            'a8': board[0], 'b8': board[1], 'c8': board[2], 'd8': board[3], 'e8': board[4], 'f8': board[5], 'g8': board[6], 'h8': board[7]
        };

        // Checking for White's castling availability
        if (positionMap[whiteKingInitialPos] === 'K') {
            if (positionMap[whiteKingsideRookInitialPos] === 'R') {
                castlingAvailability += 'K';
            }
            if (positionMap[whiteQueensideRookInitialPos] === 'R') {
                castlingAvailability += 'Q';
            }
        }

        // Checking for Black's castling availability
        if (positionMap[blackKingInitialPos] === 'k') {
            if (positionMap[blackKingsideRookInitialPos] === 'r') {
                castlingAvailability += 'k';
            }
            if (positionMap[blackQueensideRookInitialPos] === 'r') {
                castlingAvailability += 'q';
            }
        }

        return castlingAvailability || '-';
    }

    function switchToGameMode() {
        let currentFEN = board.fen();
        let startPositionFEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR';

        if (currentFEN === startPositionFEN) {
            // If current FEN is the starting position, initialize the board with 'start'
            board = Chessboard('myBoard', { ...gameConfig, position: 'start' });
        } else {
            // If not the starting position, do the existing logic
            let castle = determineCastlingAvailability(currentFEN);
            let turn = document.getElementById('whiteTurn').checked ? 'w' : 'b';
            let en_passant = '-';
            let halfmove_clock = 0;
            let fullmove_number = currentFEN.split(' ')[5] ? parseInt(currentFEN.split(' ')[5]) : 23;
            currentFEN = `${currentFEN.split(' ')[0]} ${turn} ${castle} ${en_passant} ${halfmove_clock} ${fullmove_number}`;

            if (currentFEN.split(' ').length === 6) {
                game.load(currentFEN); // Load the FEN into the game logic
            } else {
                console.error('Invalid FEN:', currentFEN);
            }
            board = Chessboard('myBoard', { ...gameConfig, position: currentFEN });
        }
    }


    function switchToPracticeMode() {
        board = Chessboard('myBoard', practiceConfig);
    }

    function toggleGameMode() {
        const isGameMode = document.getElementById('gameModeRadio').checked;
        const practiceModeDiv = document.getElementById('practiceMode');
        const gameModeDiv = document.getElementById('gameMode');

        // Check if switching from game mode to practice mode and if at least one move has been made
        if (!isGameMode && game.history().length > 0) {
            // Show confirmation dialog
            const confirmSwitch = confirm("You are about to leave Game Mode with moves made. Are you sure you want to switch to Practice Mode? Any progress will be lost.");

            // If user cancels, do not switch modes
            if (!confirmSwitch) {
                // Revert radio button selection to Game Mode
                document.getElementById('gameModeRadio').checked = true;
                return;
            }
        }
        if (isGameMode) {
            practiceModeDiv.style.display = 'none';
            gameModeDiv.style.display = 'block';
            switchToGameMode();
        } else {
            practiceModeDiv.style.display = 'block';
            gameModeDiv.style.display = 'none';
            switchToPracticeMode();
        }
        updateBoardOrientation(); // Update orientation when toggling modes
    }

    
    // Function to get move explanation from the server
    function getMoveExplanation(fen, move1, move2) {
        // Create a new chess game instance and load the FEN
        const chess = new Chess(fen);
    
        // Verify if move1 and move2 are in valid UCI format and are legal moves
        if (!isValidUCIMove(chess, move1) || !isValidUCIMove(chess, move2)) {
            alert('Invalid or illegal move. Please enter valid UCI moves.');
            return;
        }
    
        // Proceed with fetching the explanation if moves are valid and legal
        const url = new URL(`${config.API_URL}/explain_move_lambda`);
        url.searchParams.append('fen', fen);
        url.searchParams.append('m1', move1);
        url.searchParams.append('m2', move2);
    
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok: ' + response.statusText);
                }
                return response.json(); // Or response.text() if the response is plain text
            })
            .then(data => {
                console.log(data); // Check the structure of 'data'
                const explanationText = `Here is the AI analysis of ${move1} and ${move2}:<br>${data.generation}`; 
                // Inserting the HTML into the DOM
                document.getElementById('moveExplanation').innerHTML = explanationText; 
            })
            .catch(error => console.error('Error:', error));
    }
    
    function isValidUCIMove(chess, move) {
        try {
            // Attempt to make the move
            const moveObj = chess.move(move, {sloppy: true});
    
            // If the move was successful, undo it and return true
            if (moveObj) {
                chess.undo();
                return true;
            }
        } catch (error) {
            // If an error is caught (invalid move), log it and return false
            console.error('Error in isValidUCIMove:', error.message);
        }
        return false;
    }
    
    const accordionHeader = document.querySelector('.accordion-header');
    const accordionContent = document.querySelector('.accordion-content');
    const accordion = document.querySelector('.accordion');

    const setFenBtn = document.getElementById('setFenBtn');
    const fenInput = document.getElementById('fenInput');
    // const chessboard = game /* Your chessboard object */;

    setFenBtn.addEventListener('click', function() {
        const fen = fenInput.value.trim();
        const validationResult = validateFen(fen);
        if (validationResult.ok) {
            try {
                game.load(fen); // Attempt to load the FEN
                board.position(game.fen()); // Update the board position if successful
            } catch (e) {
                // Handle the error if the FEN is invalid
                console.error(e);
                alert('The FEN is valid, but failed to load. Please check the game state.');
            }
        } else {
            // If the FEN fails initial validation, alert the user
            alert(`Invalid FEN: ${validationResult.error}`);
        }
    });

    accordionHeader.addEventListener('click', function () {
        accordionContent.style.display = accordionContent.style.display === 'block' ? 'none' : 'block';
        accordion.classList.toggle('active');
    });

    // Add event listeners to the radio buttons
    document.getElementById('gameModeRadio').addEventListener('change', toggleGameMode);
    document.getElementById('practiceModeRadio').addEventListener('change', toggleGameMode);
    document.getElementById('flipOrientationCheckbox').addEventListener('change', updateBoardOrientation);

    document.getElementById('getMoveBtn').addEventListener('click', function () {
        getSuggestedMove();
    });

    // Event listener for "Explain Move" button
    document.getElementById('getAnalysisBtn').addEventListener('click', function () {
        document.getElementById('explainMovePopup').style.display = 'block';
    });

    // Event listener for OK button in the popup
    document.getElementById('explainMoveOk').addEventListener('click', function () {
        const move1 = document.getElementById('inputMove1').value;
        const move2 = document.getElementById('inputMove2').value;
        const currentFEN = game.fen();
        getMoveExplanation(currentFEN, move1, move2);
    });

    // Event listener for Cancel button in the popup
    document.getElementById('explainMoveCancel').addEventListener('click', function () {
        document.getElementById('explainMovePopup').style.display = 'none';
    });

    document.getElementById('promoteToQueen').addEventListener('click', () => selectPromotion('q'));
    document.getElementById('promoteToRook').addEventListener('click', () => selectPromotion('r'));
    document.getElementById('promoteToBishop').addEventListener('click', () => selectPromotion('b'));
    document.getElementById('promoteToKnight').addEventListener('click', () => selectPromotion('n'));

    onePlayerRadio.addEventListener('change', function () {
        if (this.checked) {
            eloInputDiv.style.display = 'block';
        }
    });

    twoPlayersRadio.addEventListener('change', function () {
        if (this.checked) {
            eloInputDiv.style.display = 'none';
        }
    });

    $('#startBtn').on('click', () => board.start());
    $('#clearBtn').on('click', () => board.clear());
    $('#undoBtn').on('click', undoMove);

});